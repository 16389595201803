@tailwind base;
@tailwind components;
@tailwind utilities;

// main style
#__next {
    //--gradient-color-1: #25C5ED;
    //--gradient-color-2: #6E60E6;
    //--gradient-color-3: #F66DBF;
    //--gradient-color-4: #E6396D;
    --navbar-height: 80px;

    --default-max-width: 1200px;
    --hero-max-width: 1400px;

    --default-black-color: #131313; // Black
    --default-dark-color: #4f4f4f; // Dark
    --default-text-color: #4B5563; // Gray
}

body {
    //background: greenyellow;
}

button {
    &:focus-visible {
        outline-style: auto !important;
        //outline-offset: 4px;
        //outline-width: 10px;
        //outline-color: red;
    }

}

input:checked + div {
    @apply border-[#25C5ED];
}

input:checked + div svg {
    @apply block;
}

@layer utilities {
    .balanced {
        text-wrap: balance;
    }

    .default-max-w {
        max-width: 1200px;
        //background: red;
        &.wide {
            max-width: 1400px;
        }
    }

    .hero-max-w {
        max-width: 100%;

        @media(min-width: 640px) {
            max-width: 100%;
            //background: orange;
        }
        @media(min-width: 768px) {
            max-width: 100%;
            //background: red;
        }
        @media(min-width: 1024px) {
            max-width: 100%;
            //background: lightgreen;
        }
        @media(min-width: 1280px) {
            max-width: 100%;
            //background: yellow;
        }
        @media(min-width: 1536px) {
            max-width: 1600px;
            //background: lightblue;
        }
    }

    .hero-container {
        font-family: Inter, sans-serif;

        // xl: 1536px
        //margin-top: 80px;
        position: relative;

        padding-top: 120px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;

        //background: blue;


        @media (max-width: 1280px) {
            // xl: 1280px
            padding-top: 160px;
            padding-bottom: 20px;
            padding-left: 20px;
            padding-right: 20px;
            //background: green;
        }

        @media (max-width: 1024px) {
            // lg: 1024px
            padding-top: 90px;
            padding-bottom: 20px;
            padding-left: 20px;
            padding-right: 20px;
            //background: orange;
        }

        @media (max-width: 768px) {
            // md: 768px
            padding-top: 80px;
            padding-bottom: 20px;
            padding-left: 20px;
            padding-right: 20px;
            //background: yellow;
        }

        @media (max-width: 640px) {
            // sm: 640px
            padding-top: 80px;
            padding-bottom: 20px;
            padding-left: 20px;
            padding-right: 20px;
            //background: pink;
        }

        .hero-container-inner {
            background: red;
            //position: absolute;
            max-width: 100%;
            width: 100%;

            //top: 0;
            //left: 0;
            //right: 0;
            //bottom: 0;
        }
    }

    .default-full-width {
        //width: unset;
        //max-width: 100%;
        //background: red;
        //width: unset;
        //margin-left: -60px;
        //margin-right: -60px;
        @media (max-width: 991.98px) {
            margin-left: -38px;
            margin-right: -38px;
        }
        @media (max-width: 640px) {
            margin-left: -28px;
            margin-right: -28px;
        }
    }

    .default-px {
        padding-left: 60px;
        padding-right: 60px;
    }

    @media (max-width: 1023px) {
        .default-px {
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    @media (max-width: 640px) {
        .default-px {
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    .default-py {
        padding-top: 56px;
        padding-bottom: 56px;
    }

    @media (max-width: 1280px) {
        .default-py {
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }
    @media (max-width: 640px) {
        .default-py {
            padding-top: 40px;
            padding-bottom: 40px;

        }
    }

    .dark-button {
        background: #1F2365;
        color: #ffffff;

        &:hover {
            background: #2F348A;
        }

        &:active {
            opacity: 50%;
        }
    }

    .purple-button {
        background: #6E60E6;
        color: #ffffff;

        &:hover {
            background: #5144BC;
        }

        &:active {
            opacity: 50%;
        }

        &.white-active {
            &:active {
                background: white;
                opacity: 50%;
                background: white;

                .button-text {
                    color: #5144BC;
                }

            }
        }
    }

    .blue-button {
        background: #25C5ED;
        color: #ffffff;

        &:hover {
            background: #21ACCF;
        }

        &:active {
            opacity: 50%;
        }
    }

    .dark-orange-button {
        @apply border-none text-white tracking-wide font-semibold transition-all;

        background: #F69400;

        &:hover {
            background: #d4840b;
        }

        &:active {
            background: #88570d;
        }
    }

    .orange-button {
        background: #E76C58;
        @apply border-none text-white tracking-wide font-semibold transition-all;
        &:hover {
            background: #E76C58;
            @apply bg-orange-700;
        }

        &:active {
            @apply bg-orange-900;
        }
    }

    .white-button {
        background: #ffffff;
        border: 1px solid #649ADE !important;

        .button-text {
            color: #649ADE;
        }

        &:hover {
            background: #4F83C5;

            .button-text {
                color: #F6FAFF;
            }
        }

        &:active {
            opacity: 50%;
        }

        &.purple-text {
            border-color: #6E60E6 !important;

            .button-text {
                color: #6E60E6;
            }

            &:hover {
                background: #6E60E6;
                color: #ffffff;

                .button-text {
                    color: #ffffff;
                }
            }
        }
    }

    .login-button {
        background: #ffffff;
        border: 2px solid #000 !important;
        color: #000;

        text-align: center;
        font-family: Inter, serif;
        font-size: 14.17px;
        font-style: normal;
        font-weight: 800;
        line-height: 21.255px; /* 150% */
        letter-spacing: 0.028px;
        text-transform: uppercase;

        .button-text {
            color: #000;

        }

        &:hover {
            opacity: 50%;
            //background: #4F83C5;
            //
            //.button-text {
            //    color: #F6FAFF;
            //}
        }

        &:active {
            opacity: 20%;
        }

        &.purple-text {
            border-color: #6E60E6 !important;

            .button-text {
                color: #6E60E6;
            }

            &:hover {
                background: #6E60E6;
                color: #ffffff;

                .button-text {
                    color: #ffffff;
                }
            }
        }
    }

    .pink-button {
        //background: #F66DBF;
        background: #E5319D;

        &:hover {
            //background: #E5319D;
            background: #ad2677;
        }

        &:active {
            opacity: 50%;
        }

    }

    .navbar-button {
        // background: none;

        &:hover {
            opacity: 70%;
        }

        &:active {
            opacity: 40%;
        }

        // border: 1.75px solid #FCFCFC;

   
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        /* Desktop/Link Medium */
        font-family: Poppins, serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px; /* 177.778% */
        letter-spacing: 0.75px;
    }


    .box {

        //:before {
        //    content: "";
        //    display: block;
        //    padding-top: 100%;
        //    border: 1px solid black;
        //
        //    background: #1CC8E1;
        //}
        //
        position: relative;
        //background-color: #ff0;
        //margin: 30px;

        ::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border: 5px solid #000;
        }
    }
}

/* For homepage stripe.com style background */
#gradient-canvas {
    --gradient-color-1: #25C5ED;
    --gradient-color-2: #6E60E6;
    --gradient-color-3: #F66DBF;
    --gradient-color-4: #E6396D;
}

// #A9E8F8
#gradient-canvas-teachers {
    --gradient-color-1: #6E60E6;
    --gradient-color-2: #F66DBF;
    --gradient-color-3: #78c7db;
    --gradient-color-4: #25C5ED;
}


.card-shadow {
    box-shadow: 0 8.69382px 39.1222px rgba(176, 176, 176, 0.15);
    //background: red;
}

.rc-collapse-header {
    //background: lightgreen;
    //justify-content: center;
    //align-items: center !important;

}

.rc-collapse-header-text {
    color: #131313;
    font-weight: 300;
    line-height: 1.5;
    padding-right: 30px;
    text-wrap: balance;

    &:hover {
        opacity: 75%;
    }

    &:active {
        opacity: 50%;
    }

    font-size: 28px;
    @media (max-width: 1280px) {
    }

    @media (max-width: 1024px) {
        font-size: 22px;
    }

    @media (max-width: 768px) {
        font-size: 20px;
    }
}


.structured-text-wrapper {
    //background:red;
    color: black;

    ul {
        //margin: 0px;
        //padding: 0px;
        text-align: center;
        display: inline-block;
        //list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 800px) {
            text-align: left;
            align-items: flex-start;
        }

    }

    li {
        //text-align: center;
        //display: inline-block;
        //background: green;
        float: left;
        clear: left;
        //background-color: #b70505;
        color: black;
        font-weight: 400;
        //font-size: 23px;
        font-size: 14px;
        //border-radius: 25px;
        //margin-top: 5px;
        //padding: 10px;
        //max-width: 200px;
        word-wrap: break-word;
        //display: inline-block;
        text-align: left;

        p {
            text-align: left;
            margin-block-end: 10px;
            margin-block-start: 10px;
            //@media (max-width: 1024px) {
            //    text-align: left;
            //    background: red;
            //}
        }

        //@media (max-width: 1280px) {
        //    font-size: 14px;
        //}

    }

}


.google-signup-button {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 16px;

    color: #1F1F1F;
    background: #F2F2F2;
    border: 1.5px solid #D1D5DB;

    //background: red;
    &:hover {
        background: #F9FAFB;
    }

    &:active {
        background: #E9EAEB;
    }
}


@layer utilities {
    .flex-center {
        @apply flex items-center justify-center;
    }

    .bg-red, .red {
        background: red;
    }

    .bg-green, .green {
        background: green;
    }

    .bg-blue, .blue {
        background: blue;
    }

    .border-red {
        @apply border border-solid border-red-500;
    }
}


@layer components {
    #__next {
        overflow: hidden;
    }

    .button-group {
        @apply w-full;
        @apply flex flex-col justify-center items-center gap-4;
        @apply md:flex-row;
        @apply lg:justify-start;

        /*
        width: 100%;
        //margin-top: 1.75rem;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        //background: red;
        //@apply bg-red-200;

        // xs breakpoint
        @media(min-width: 430px) {
            // sm breakpoint
            //background: yellow;
        }

        @media(min-width: 640px) {
            // sm breakpoint
            //background: orange;
        }
        @media(min-width: 768px) {
            // md breakpoint
            flex-direction: row;
        }
        @media(min-width: 1024px) {
            // lg breakpoint
            // left justify buttons on desktop
            //align-items: start;
            justify-content: start;
        }
        @media(min-width: 1280px) {
            // xl breakpoint
            //flex-direction: row;
            //justify-content: start;
        }
        @media(min-width: 1536px) {
            // 2xl breakpoint
        }
        */
        &.center {
            justify-content: center;
            align-items: center;
        }
    }

    .founders-panel {
        @apply drop-shadow-2xl;
        @apply border-8 border-solid border-white bg-[#F9FAFB];
        @apply rounded-3xl;
        // the drop shadow under the founders panel sometimes flickers on older devices when
        // a transform exists on the page - this is a possible fix for that:
        -webkit-transform-style: preserve-3d;
        -webkit-backface-visibility: hidden;
    }

    /* this is the 'Contents' structured text found on each component */
    .structured-text-block {
        @apply font-Inter text-left select-none text-[#4B5563];
        //background: red;
        //user-select: none;
        //font-family: Inter, sans-serif;
        //text-align: left;
        //color: #4B5563;

        &.text-center {
            text-align: center;
        }

        &.text-right {
            text-align: right;
        }


        h2 {
            //background: green;

            //mx-auto
            //my-4
            margin: 1em auto;

            //whitespace-normal
            //no-whitespace-pre-line
            //lg:whitespace-pre-line
            //xl:whitespace-pre-line
            //2xl:whitespace-pre-line
            white-space: pre-line;


            //font-medium
            color: #131313;
            font-weight: 500;
            line-height: 1.16;

            font-size: 32px;
            @media(min-width: 640px) {
                // sm breakpoint
                font-size: 36px;
            }
            @media(min-width: 768px) {
                // md breakpoint
                font-size: 36px;
            }
            @media(min-width: 1024px) {
                // lg breakpoint
                font-size: 40px;
            }
            @media(min-width: 1280px) {
                // xl breakpoint
                font-size: 44px;
            }
            @media(min-width: 1536px) {
                // 2xl breakpoint
                font-size: 48px;
            }
        }

        &.header-center {
            h2 {
                text-align: center;
            }
        }

        &.header-left-md {
            h2 {
                @media(min-width: 768px) {
                    // md breakpoint
                    text-align: left;
                }
            }
        }

        p {
            font-size: 17px;
            line-height: 1.4;

            //background: green;
            @media(min-width: 640px) {
                // sm breakpoint
                //font-size: 17px;
                //background: orange;
            }
            @media(min-width: 768px) {
                // md breakpoint
                font-size: 18px;
                //background: red;
            }
            @media(min-width: 1024px) {
                // lg breakpoint
                font-size: 18px;
                //background: lightgreen;
            }
            @media(min-width: 1280px) {
                // xl breakpoint
                font-size: 19px;
                //background: yellow;
            }
            @media(min-width: 1536px) {
                // 2xl breakpoint
                font-size: 20px;
                //background: lightblue;
            }

            width: 100%;
            margin-left: auto;
            margin-right: auto;

            strong {
                //background: orange;
                font-weight: 700;
            }
        }

        &.hero-text {
            text-align: center;

            p {
                line-height: 1.5;
            }
        }

        &.list-style-cyan-dots {

            ol {
                list-style-type: none;
                counter-reset: item;
                margin: 0;
                padding: 0;

                li {
                    counter-increment: item;
                    margin-bottom: 5px;
                    display: flex;
                    align-items: center;
                }

                li::before {
                    @apply font-Inter text-white text-3xl font-black;

                    @apply aspect-square;

                    content: counter(item);
                    width: 50px;
                    height: 50px;
                    margin-right: 20px;
                    //font-size: 46.26px;
                    background-color: #1CC8E1;
                    color: white;
                    border-radius: 50%;
                    padding: 0.2em;
                    text-align: center;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

            }

        }


    }

    .structured-text-block--new {
        @apply structured-text-block;

    }

    // the structured text found on a blog post
    .structured-text-block--blog {
        // apply default text style
        @apply font-Inter text-[#4f4f4f] text-lg;

        ul {
            margin: 24px 0;
            list-style-image: url('/assets/icons/red-bullet.svg');

            li {
                margin: 12px 0;
                padding-left: 8px;
            }
        }

        blockquote {
            border-left: 5px solid #34363a;
            color: #34363a;
            padding-left: 20px;
            padding-top: 10px;
            padding-bottom: 10px;
            font-size: 1.05em;
            font-style: normal;
            margin: 1.5em 0;
            position: relative;
            transition: margin-bottom .2s .3s ease-in-out;
            // first p
            p:first-child {
                //background: red;
                margin-top: 0;
            }

            footer {
                position: relative;
                color: #848484;
                display: flex;
                font-style: italic;


                &:before {
                    content: "";
                    display: block;
                    pointer-events: none;
                    width: 20px;
                    height: 1px;
                    background: #aaaaaa;
                    margin-right: 15px;
                    margin-top: 0.6em;

                }
            }
        }
    }

    .headline-left {
        text-align: left !important;
    }

    .headline-center {
        text-align: center !important;
    }

    .rounded-play-btn {
        z-index: 8;
        width: 80px;
        height: 80px;
        //background-color: pink;
        border-radius: 50%;
        background: linear-gradient(135deg, #e637a8 2.69%, #e68137 75%);

        &:hover {
            background: #e637a8;
        }

        &:active {
            background: linear-gradient(180deg, #e637a8 0%, #d11f4a 100%);
        }

        &:focus {
            box-shadow: 0 0 8px 2px #1cc8e1;
        }

        border: 4px solid #ffffff;

        cursor: pointer;

        .triangle {
            line-height: 1;
            width: 18px;
            height: 18px;
            border-top-right-radius: 20%;
            background-color: #fff;
            text-align: left;
            display: inline-block;
            margin: 1rem;
            transition: background-color 0.45s ease;
            //@media (max-width: 440px) {
            //width: 13px;
            //height: 13px;
            //}
            &:hover {
                background-color: #ffffff;
            }

            &:before,
            &:after {
                content: '';
                position: absolute;
                background-color: inherit;
                width: 100%;
                height: 100%;
                border-top-right-radius: 20%;
            }

            &.right {
                transform: rotate(30deg) skewX(-30deg) scale(1, 0.866);
                transform-origin: 72% 82%;
            }

            &:before {
                transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
            }

            &:after {
                transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
            }
        }
    }

    .orange-pink-play-btn {
        @apply rounded-play-btn;
        background: linear-gradient(135deg, #e637a8 2.69%, #e68137 75%);

        &:hover {
            background: #e637a8;
        }

        &:active {
            background: linear-gradient(180deg, #e637a8 0%, #d11f4a 100%);
        }
    }

    .dark-orange-play-btn {
        @apply rounded-play-btn;

        background: #F69400;

        &:hover {
            background: #d4840b;
        }

        &:active {
            background: #88570d;
        }
    }

    .purple-play-btn {
        @apply rounded-play-btn;

        background: #6E60E6;
        color: #ffffff;

        &:hover {
            background: #5144BC;
        }

        &:active {
            opacity: 50%;
        }
    }

    .rich-text-new {
        @apply font-Poppins;
        @apply text-sm sm:text-lg leading-relaxed;

        h1 {
            @apply balanced;
        }

        h2 {
            @apply balanced;
            @apply font-medium md:font-semibold;
            @apply text-left;
            line-height: 1.4;
            //@apply text-center;

            font-size: 30px;

            @media(max-width: 1536px) {
                // 2xl: 1536xpx
                @apply text-[30px];
            }

            @media (max-width: 1280px) {
                // xl: 1280px
                @apply text-[28px];
            }

            @media (max-width: 1024px) {
                // lg: 1024px
                @apply text-[28px];
                @apply text-center;
            }

            @media (max-width: 768px) {
                // md: 768px
                @apply text-[25px];
                @apply text-center;
            }

            @media (max-width: 640px) {
                // sm: 640px
                @apply text-[22px];
                @apply text-center;
            }

            //@apply leading-[1.3];
            @apply my-0;
        }

        &.center-header {
            h2 {
                @apply text-left sm:text-center;
            }
        }

        h5 {
            @apply text-[#CFCFCF] m-0 text-center  font-normal font-Poppins;
            @apply leading-relaxed lg:text-left;
            @apply text-sm sm:text-base;
        }

        p {
            @apply text-left;

            strong {
                font-weight: 600;
            }

        }

        ul {
            margin-left: 4px;
            //margin: 0;
            padding: 0 0 0 14px;
            //margin: 24px 0;
            //list-style-image: url('/assets/icons/teal-bullet.svg');

            li {
                //margin: 12px 0;
                //padding-left: 8px;
                p {
                    margin: 0;
                }
            }
        }

    }
}


@layer components {

    .VideoHeroBackground {

        //background-size: 200% 200%;

        background-size: cover;
        //background-position: center center;
        background-position-x: 50%;
        background-position-y: top;
        background-repeat: no-repeat;

        //transform: scale(2);
        //transform-origin: center;
        position: relative;


        .dark-overlay {
            background-image: linear-gradient(277deg, rgba(0, 0, 0, .7), transparent 90%, rgba(0, 0, 0, .28));
            background-color: rgba(0, 0, 0, .5);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-clip: border-box;
            background-origin: padding-box;
            background-size: auto;
            background-repeat: repeat;
        }

    }


    .StripeField {
        @apply relative w-full overflow-clip;

        fond-family: Ideal Sans, sans-serif;
        border: 1px solid #e6e6e6;
        background-color: #ffffff;
        border-radius: 5px;

        transition: height .34s ease, opacity .4s ease .2s, background .15s ease, border .15s ease, box-shadow .15s ease, color .15s ease !important;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.02);

        .StripeLabel {
            // p-FieldLabel Label p-FieldLabel--floating Label--floating
            @apply absolute pb-1 ml-[10px] text-[16px] text-[#30313d] opacity-[0.8];
            transform: translateY(10px) scale(0.8888888888888888);
            transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);

            font-size: 16px !important;
            margin-left: 13px !important;
            pointer-events: none;
            position: absolute;
            transform-origin: top left !important;
            will-change: transform;
            z-index: 2;

            &.StripeLabel-resting {
                transform: translateY(18px) scale(1) !important;
            }
        }


        &.StripeField-focus {
            outline: 0;
            border-color: #0573e180;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px #0573e140, 0 1px 1px 0 #00000014;
        }

    }

}

@layer utilities {

    .blur-box-background {
        background-color: #00000033;
        -webkit-backdrop-filter: blur(45px);
        backdrop-filter: blur(45px);
    }

}